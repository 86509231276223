export default {
  colors: {
    'blue.darker': {
      '50': '#EBF0F9',
      '100': '#C8D4EF',
      '200': '#A5B9E4',
      '300': '#819DDA',
      '400': '#5E81CF',
      '500': '#3A66C5',
      '600': '#2F519D',
      '700': '#233D76',
      '800': '#17294F',
      '900': '#0C1427',
    },
    'blue.light': {
      '50': '#E6F0FE',
      '100': '#B9D4FD',
      '200': '#8DB9FC',
      '300': '#609EFB',
      '400': '#3382FA',
      '500': '#3483FA',
      '600': '#0552C7',
      '700': '#043E95',
      '800': '#022964',
      '900': '#011532',
    },
    blue: {
      '50': '#E5EFFF',
      '100': '#B8D3FF',
      '200': '#8AB7FF',
      '300': '#5C9BFF',
      '400': '#2E7FFF',
      '500': '#00317E',
      '600': '#004FCC',
      '700': '#003C99',
      '800': '#002866',
      '900': '#001433',
    },
    cyan: {
      '50': '#EAF9FA',
      '100': '#C5EEF1',
      '200': '#A0E2E8',
      '300': '#7BD7E0',
      '400': '#56CCD7',
      '500': '#30BCC9',
      '600': '#279AA5',
      '700': '#1D747C',
      '800': '#144D52',
      '900': '#0A2729',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
    green: {
      '50': '#F3F6EF',
      '100': '#DEE5D2',
      '200': '#C9D4B5',
      '300': '#B4C397',
      '400': '#9FB37A',
      '500': '#8AA25D',
      '600': '#6E824A',
      '700': '#536138',
      '800': '#374125',
      '900': '#1C2013',
    },
    yellow: {
      '50': '#F9FAEB',
      '100': '#EDF0C6',
      '200': '#E2E7A2',
      '300': '#D6DE7D',
      '400': '#CBD459',
      '500': '#BFCB34',
      '600': '#99A22A',
      '700': '#737A1F',
      '800': '#4C5115',
      '900': '#26290A',
    },
    red: {
      '50': '#FFF5F5',
      '100': '#FED7D7',
      '200': '#FEB2B2',
      '300': '#FC8181',
      '400': '#F56565',
      '500': '#E53E3E',
      '600': '#C53030',
      '700': '#9B2C2C',
      '800': '#822727',
      '900': '#63171B',
    },
    orange: {
      '50': '#FCECE9',
      '100': '#F6CBC1',
      '200': '#F0A999',
      '300': '#EA8771',
      '400': '#E46549',
      '500': '#DE4421',
      '600': '#B2361A',
      '700': '#852914',
      '800': '#591B0D',
      '900': '#2C0E07',
    },
  },
};
