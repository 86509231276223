import { Icon } from '@chakra-ui/react';

export default function Sync(props) {
  return (
    <Icon viewBox='0 0 18 25' {...props}>
      <path
        d='M9 3.40909V0L4.5 4.54545L9 9.09091V5.68182C12.7238 5.68182 15.75 8.73864 15.75 12.5C15.75 13.6477 15.4687 14.7386 14.9625 15.6818L16.605 17.3409C17.4825 15.9432 18 14.2841 18 12.5C18 7.47727 13.9725 3.40909 9 3.40909ZM9 19.3182C5.27625 19.3182 2.25 16.2614 2.25 12.5C2.25 11.3523 2.53125 10.2614 3.0375 9.31818L1.395 7.65909C0.5175 9.05682 0 10.7159 0 12.5C0 17.5227 4.0275 21.5909 9 21.5909V25L13.5 20.4545L9 15.9091V19.3182Z'
        fill='currentColor'
      />
    </Icon>
  );
}
