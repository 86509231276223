export default {
  colors: {
    blue: {
      '50': '#F2F8FD',
      '100': '#E4F0FA',
      '200': '#C3E0F4',
      '300': '#8EC8EB',
      '400': '#48A6DC',
      '500': '#2C91CB',
      '600': '#1D73AC',
      '700': '#185C8C',
      '800': '#184F74',
      '900': '#194261',
      '950': '#112A40',
    },
    'regal-blue': {
      '50': '#F0F7FF',
      '100': '#E0EFFE',
      '200': '#BADFFD',
      '300': '#7DC5FC',
      '400': '#38A8F8',
      '500': '#0E8DE9',
      '600': '#026FC7',
      '700': '#0358A1',
      '800': '#074B85',
      '900': '#0C406F',
      '950': '#082849',
    },

    cyan: {
      '50': '#EAF9FA',
      '100': '#C5EEF1',
      '200': '#A0E2E8',
      '300': '#7BD7E0',
      '400': '#56CCD7',
      '500': '#30BCC9',
      '600': '#279AA5',
      '700': '#1D747C',
      '800': '#144D52',
      '900': '#0A2729',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
    green: {
      '50': '#EBFAF5',
      '100': '#C6F1E4',
      '200': '#A1E8D2',
      '300': '#7CDFC1',
      '400': '#57D5AF',
      '500': '#1A6951',
      '600': '#28A47E',
      '700': '#1E7B5F',
      '800': '#14523F',
      '900': '#0A2920',
    },
    yellow: {
      '50': '#FFF9E5',
      '100': '#FFEDB8',
      '200': '#FFE18A',
      '300': '#FFD55C',
      '400': '#FFCA2E',
      '500': '#FFBE00',
      '600': '#CC9800',
      '700': '#997200',
      '800': '#664C00',
      '900': '#332600',
    },
    red: {
      '50': '#FFF5F5',
      '100': '#FED7D7',
      '200': '#FEB2B2',
      '300': '#FC8181',
      '400': '#F56565',
      '500': '#E53E3E',
      '600': '#C53030',
      '700': '#9B2C2C',
      '800': '#822727',
      '900': '#63171B',
    },
    orange: {
      '50': '#FFFAF0',
      '100': '#FEEBC8',
      '200': '#FBD38D',
      '300': '#F6AD55',
      '400': '#ED8936',
      '500': '#DD6B20',
      '600': '#C05621',
      '700': '#9C4221',
      '800': '#7B341E',
      '900': '#652B19',
    },
    massala: {
      '50': '#F6F6F6',
      '100': '#E7E7E7',
      '200': '#D1D1D1',
      '300': '#B0B0B0',
      '400': '#888888',
      '500': '#6D6D6D',
      '600': '#5D5D5D',
      '700': '#4F4F4F',
      '800': '#414141',
      '900': '#3D3D3D',
      '950': '#262626',
    },
    black: {
      '50': '#EDEDED',
      '100': '#CFCFCF',
      '200': '#A3A3A3',
      '300': '#616161',
      '400': '#1B1B1B',
      '500': '#000000',
      '600': '#000000',
      '700': '#000000',
      '800': '#000000',
      '900': '#000000',
      '950': '#000000',
    },
    orchidwhite: {
      '50': '#FFFFFE',
      '100': '#FFFFFC',
      '200': '#FEFFF9',
      '300': '#FEFFF5',
      '400': '#FFFFF3',
      '500': '#FFFEF3',
      '600': '#FFF2B2',
      '700': '#FED771',
      '800': '#F5B943',
      '900': '#EDA31A',
      '950': '#8C5A00',
    },
  },
};
