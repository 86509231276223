import { Button, type ButtonProps } from '@chakra-ui/react';
import Sync from '@public/icons/actions/sync';
import { Poppins } from 'next/font/google';
import { useDesignTokens } from '../../providers/design_tokens_provider';

export interface SyncButtonProps {
  onClick: () => void;
  size?: ButtonProps['size'];
  isDisabled?: boolean;
  isLoading?: boolean;
}

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

export default function SyncButton({
  onClick,
  size = 'lg',
  isDisabled = false,
  isLoading = false,
}: SyncButtonProps) {
  const tokens = useDesignTokens();
  return (
    <Button
      isLoading={isLoading}
      isDisabled={isDisabled}
      colorScheme={tokens.button.primary.scheme}
      variant='outline'
      size={size}
      fontFamily={poppins.style.fontFamily}
      leftIcon={<Sync color={tokens.text.primary.color} />}
      style={{
        width: '150px',
        fontWeight: 300,
        borderRadius: '7px',
        boxShadow: 'none',
        padding: '1rem',
      }}
      onClick={onClick}
    >
      Ativar
    </Button>
  );
}
