import { Box, Card, CardBody } from '@chakra-ui/react';
import { Tree } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';
import { useEffect, useState } from 'react';

export interface TreeListPops {
  inputData: TreeListData[];
  checkStrictly: boolean;
  onCheck: (info: { checkedKeys: string[]; halfCheckedKeys: string[] }) => void;
}

export interface TreeListData {
  id: string;
  label: string;
  selected: boolean;
  open: boolean;
  parentId?: string;
}

export default function TreeList({
  inputData,
  onCheck,
  checkStrictly,
}: TreeListPops) {
  const [data, setData] = useState<DataNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const setTreeData = (input: TreeListData[]) => {
    const getChildren = (id: string) => {
      return parse(input.filter((i) => i.parentId === id));
    };

    const parse = (list: TreeListData[]): DataNode[] => {
      return list.map((el) => {
        return {
          key: el.id,
          title: el.label,
          children: getChildren(el.id),
        };
      });
    };

    const treeData = parse(input.filter((el) => !el.parentId));
    const checked = input.filter((i) => i.selected).map((i) => i.id);
    const expanded = input.filter((i) => i.open).map((i) => i.id);
    setData(treeData);
    setCheckedKeys(checked);
    setSelectedKeys(checked);
    setExpandedKeys(expanded);
  };

  useEffect(() => {
    setTreeData(inputData);
  }, []);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
  };

  const onCheckEntry = (
    checkedKeysValue: string[],
    info: { halfCheckedKeys: string[] },
  ) => {
    setCheckedKeys(checkedKeysValue);
    if (checkStrictly) {
      onCheck({
        checkedKeys: (checkedKeysValue as unknown as { checked: string[] })
          .checked,
        halfCheckedKeys: info.halfCheckedKeys ?? [],
      }); // see: TreeProps at antd/es/tree
    } else {
      onCheck({
        checkedKeys: checkedKeysValue,
        halfCheckedKeys: info.halfCheckedKeys ?? [],
      });
    }
  };

  const onSelect = (selectedKeysValue: React.Key[]) => {
    setSelectedKeys(selectedKeysValue);
  };

  return (
    <Card minH='50vh'>
      <CardBody>
        <Box>
          <Tree
            checkable
            checkStrictly={checkStrictly}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={false}
            onCheck={onCheckEntry as TreeProps['onCheck']}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={data}
            selectable={false}
          />
        </Box>
      </CardBody>
    </Card>
  );
}
