'use client';

import { handleAPIReq, onboardingAPIV2Client } from '@/api';
import { type StatusLabel, statusName } from '@/utils/constants';
import { unaccentLower } from '@commons/js-utils';
import { LegalEntityType } from '@medsimples/doctor-onboarding-openapi-v2';
import { useQuery } from '@tanstack/react-query';
import * as E from 'fp-ts/Either';

export const getStatusesCountComponent = (
  auth,
  legalEntityType: LegalEntityType,
) => {
  return useQuery({
    queryKey: ['statusCount'],
    enabled: Boolean(auth.user),
    queryFn: async () => {
      const r = await handleAPIReq(() =>
        onboardingAPIV2Client.admin.statusesCount({ legalEntityType }),
      );
      if (E.isLeft(r)) {
        throw r.left;
      }

      return r.right.data;
    },
  });
};

export const closeExportModalComponent = async (
  setIsLoading,
  searchParams: URLSearchParams,
  errorToast,
  setIsExportReportModalOpen,
  emitReport?: boolean,
  withFilters?: boolean,
  legalEntityType?: LegalEntityType,
) => {
  if (emitReport) {
    setIsLoading(true);
    const reqBody = withFilters
      ? {
          search: searchParams.get('search'),
          sorting: {
            sort: searchParams.get('sort'),
            order: searchParams.get('order'),
          },
          filters: JSON.parse(searchParams.get('filters') ?? '{}'),
          legalEntityType: legalEntityType || LegalEntityType.COMPANY,
        }
      : {
          search: null,
          sorting: {
            sort: null,
            order: null,
          },
          filters: JSON.parse(searchParams.get('filters') ?? '{}'),
          legalEntityType: legalEntityType || LegalEntityType.COMPANY,
        };
    const reportRes = await fetch(
      `${window.location.origin}/api/v1/admin/professional/export-report`,
      {
        method: 'POST',
        body: JSON.stringify(reqBody),
      },
    )
      .catch((err) => {
        console.error(err);
        return null;
      })
      .finally(() => setIsLoading(false));

    if (!reportRes?.ok) {
      errorToast('Erro inesperado ao exportar relatório');
      return;
    }

    const filters = searchParams.get('filters')
      ? JSON.parse(searchParams.get('filters'))
      : {};

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(await reportRes.blob());
    link.download = unaccentLower(
      `medicos-${
        statusName[filters.status || 'ALL']
      }-${new Date().toLocaleDateString('pt-BR')}.xlsx`.replaceAll(' ', '-'),
    );
    link.click();
  }
  setIsExportReportModalOpen(false);
};

export const getAdmin = (auth) => {
  return useQuery({
    queryKey: ['me'],
    enabled: Boolean(auth.user),
    queryFn: () => {
      return handleAPIReq(() => onboardingAPIV2Client.admin.meAdmin()).then(
        (res) => {
          if (E.isLeft(res)) {
            throw res.left;
          }
          return res.right.data;
        },
      );
    },
  });
};

export const findStatusIndexComponent = (
  value: StatusLabel,
  searchParams: URLSearchParams,
) => {
  const filters = JSON.parse(searchParams.get('filters') ?? '{}');
  return filters?.status?.length === 1
    ? filters.status[0] === value
    : 'ALL' === value;
};
