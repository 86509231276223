export default {
  colors: {
    'blue.darker': {
      '50': '#EBF0F9',
      '100': '#C8D4EF',
      '200': '#A5B9E4',
      '300': '#819DDA',
      '400': '#5E81CF',
      '500': '#3A66C5',
      '600': '#2F519D',
      '700': '#233D76',
      '800': '#17294F',
      '900': '#0C1427',
    },
    'blue.light': {
      '50': '#E6F0FE',
      '100': '#B9D4FD',
      '200': '#8DB9FC',
      '300': '#609EFB',
      '400': '#3382FA',
      '500': '#3483FA',
      '600': '#0552C7',
      '700': '#043E95',
      '800': '#022964',
      '900': '#011532',
    },
    blue: {
      '50': '#f8fcff',
      '100': '#eef6ff',
      '200': '#dbefff',
      '300': '#bae2ff',
      '400': '#97d6ff',
      '500': '#76c8ff',
      '600': '#2ea3ff',
      '700': '#008cff',
      '800': '#0078d5',
      '900': '#0864b0',
      '950': '#053e75',
    },
    cyan: {
      '50': '#EAF9FA',
      '100': '#C5EEF1',
      '200': '#A0E2E8',
      '300': '#7BD7E0',
      '400': '#56CCD7',
      '500': '#30BCC9',
      '600': '#279AA5',
      '700': '#1D747C',
      '800': '#144D52',
      '900': '#0A2729',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
    green: {
      '50': '#f2fbf3',
      '100': '#e2f6e5',
      '200': '#c6eccb',
      '300': '#9adba3',
      '400': '#66c273',
      '500': '#4ab95b',
      '600': '#30893e',
      '700': '#296c34',
      '800': '#25562d',
      '900': '#204727',
      '950': '#0d2612',
    },
    greenlight: {
      '50': '#fdfffd',
      '100': '#fbfefb',
      '200': '#f8fdf9',
      '300': '#f3fbf4',
      '400': '#ecf8ed',
      '500': '#c6eccb',
      '600': '#83d98e',
      '700': '#52c661',
      '800': '#3daa4c',
      '900': '#378a42',
      '950': '#16491f',
    },
    greendark: {
      '50': '#e2fee6',
      '100': '#bcfbc7',
      '200': '#7ef893',
      '300': '#19f340',
      '400': '#08901f',
      '500': '#044d0f',
      '600': '#03400b',
      '700': '#03320a',
      '800': '#032709',
      '900': '#031807',
      '950': '#011204',
    },
    yellow: {
      '50': '#FFF9E5',
      '100': '#FFEDB8',
      '200': '#FFE18A',
      '300': '#FFD55C',
      '400': '#FFCA2E',
      '500': '#FFBE00',
      '600': '#CC9800',
      '700': '#997200',
      '800': '#664C00',
      '900': '#332600',
    },
    red: {
      '50': '#FFF5F5',
      '100': '#FED7D7',
      '200': '#FEB2B2',
      '300': '#FC8181',
      '400': '#F56565',
      '500': '#E53E3E',
      '600': '#C53030',
      '700': '#9B2C2C',
      '800': '#822727',
      '900': '#63171B',
    },
    orange: {
      '50': '#FFFAF0',
      '100': '#FEEBC8',
      '200': '#FBD38D',
      '300': '#F6AD55',
      '400': '#ED8936',
      '500': '#DD6B20',
      '600': '#C05621',
      '700': '#9C4221',
      '800': '#7B341E',
      '900': '#652B19',
    },
    massala: {
      '50': '#f2f2f2',
      '100': '#dddddd',
      '200': '#bebebe',
      '300': '#909090',
      '400': '#5c5c5c',
      '500': '#414141',
      '600': '#373737',
      '700': '#2f2f2f',
      '800': '#272727',
      '900': '#242424',
      '950': '#171717',
    },
    black: {
      '50': '#ededed',
      '100': '#cfcfcf',
      '200': '#a3a3a3',
      '300': '#616161',
      '400': '#1b1b1b',
      '500': '#000000',
      '600': '#000000',
      '700': '#000000',
      '800': '#000000',
      '900': '#000000',
      '950': '#000000',
    },
    orchidwhite: {
      '50': '#fffffe',
      '100': '#fffffc',
      '200': '#fefff9',
      '300': '#fefff5',
      '400': '#fffff3',
      '500': '#fffef3',
      '600': '#fff2b2',
      '700': '#fed771',
      '800': '#f5b943',
      '900': '#eda31a',
      '950': '#8c5a00',
    },
  },
};
