export default {
  colors: {
    blue: {
      '50': '#e9f2fe',
      '100': '#d2e4fb',
      '200': '#9bc9f8',
      '300': '#449df2',
      '400': '#0f69ba',
      '500': '#0c406f',
      '600': '#05325f',
      '700': '#05274c',
      '800': '#06223f',
      '900': '#081d35',
      '950': '#061222',
    },
    cyan: {
      '50': '#EAF9FA',
      '100': '#C5EEF1',
      '200': '#A0E2E8',
      '300': '#7BD7E0',
      '400': '#56CCD7',
      '500': '#30BCC9',
      '600': '#279AA5',
      '700': '#1D747C',
      '800': '#144D52',
      '900': '#0A2729',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
    green: {
      '50': '#EBFAF5',
      '100': '#C6F1E4',
      '200': '#A1E8D2',
      '300': '#7CDFC1',
      '400': '#57D5AF',
      '500': '#1A6951',
      '600': '#28A47E',
      '700': '#1E7B5F',
      '800': '#14523F',
      '900': '#0A2920',
    },
    yellow: {
      '50': '#FFF9E5',
      '100': '#FFEDB8',
      '200': '#FFE18A',
      '300': '#FFD55C',
      '400': '#FFCA2E',
      '500': '#FFBE00',
      '600': '#CC9800',
      '700': '#997200',
      '800': '#664C00',
      '900': '#332600',
    },
    red: {
      '50': '#FFF5F5',
      '100': '#FED7D7',
      '200': '#FEB2B2',
      '300': '#FC8181',
      '400': '#F56565',
      '500': '#E53E3E',
      '600': '#C53030',
      '700': '#9B2C2C',
      '800': '#822727',
      '900': '#63171B',
    },
    orange: {
      '50': '#FFFAF0',
      '100': '#FEEBC8',
      '200': '#FBD38D',
      '300': '#F6AD55',
      '400': '#ED8936',
      '500': '#DD6B20',
      '600': '#C05621',
      '700': '#9C4221',
      '800': '#7B341E',
      '900': '#652B19',
    },
    purple: {
      '50': '#f4f2fd',
      '100': '#ebe8f9',
      '200': '#dbd5f5',
      '300': '#c0b3ec',
      '400': '#a088df',
      '500': '#6637c6',
      '600': '#6335a8',
      '700': '#583885',
      '800': '#4b3071',
      '900': '#3e295d',
      '950': '#27193f',
    },
    purplelight: {
      '50': '#fbf9ff',
      '100': '#f5f0ff',
      '200': '#ebdfff',
      '300': '#e1d0ff',
      '400': '#d0b2ff',
      '500': '#e1cefe',
      '600': '#bd94f7',
      '700': '#9c62eb',
      '800': '#8138df',
      '900': '#6920c2',
      '950': '#43068e',
    },
    coral: {
      '50': '#fff1f1',
      '100': '#ffdfdf',
      '200': '#ffc5c5',
      '300': '#ff9d9d',
      '400': '#ff6464',
      '500': '#ff4040',
      '600': '#ed1515',
      '700': '#c80d0d',
      '800': '#a50f0f',
      '900': '#881414',
      '950': '#4b0404',
    },
    massala: {
      '50': '#f2f2f2',
      '100': '#dddddd',
      '200': '#bebebe',
      '300': '#909090',
      '400': '#5c5c5c',
      '500': '#414141',
      '600': '#373737',
      '700': '#2f2f2f',
      '800': '#272727',
      '900': '#242424',
      '950': '#171717',
    },
    black: {
      '50': '#ededed',
      '100': '#cfcfcf',
      '200': '#a3a3a3',
      '300': '#616161',
      '400': '#1b1b1b',
      '500': '#000000',
      '600': '#000000',
      '700': '#000000',
      '800': '#000000',
      '900': '#000000',
      '950': '#000000',
    },
    orchidwhite: {
      '50': '#fffffe',
      '100': '#fffffc',
      '200': '#fefff9',
      '300': '#fefff5',
      '400': '#fffff3',
      '500': '#fffef3',
      '600': '#fff2b2',
      '700': '#fed771',
      '800': '#f5b943',
      '900': '#eda31a',
      '950': '#8c5a00',
    },
  },
};
